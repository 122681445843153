import React, { Component } from 'react';
import logo from '../assets/images/LOGO.png';
import logo_icon from '../assets/images/Vector-Smart-Object.png';
import favicon_icon from '../assets/images/favicon-icon.png';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import Axios from "axios";
import { api_url } from '../Config';
import swal from 'sweetalert';
class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            redirectToReferrer: false,
            password: "",
            loadingBtn: false,
            loginToHome: false,
            reset_approve: false,
            otp_no: 0,
            repassword: ""
        };
        this.changePassword = this.changePassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkCode = this.checkCode.bind(this);
    }
    checkCode(event) {
        event.preventDefault();
        if (this.state.otp_no === 0) {
            swal('Please enter 6 digit code.')
        } else {
            let dbotp = localStorage.getItem('reset_token_id');
            if (dbotp) {
                let userotp = this.state.otp_no;
                if (dbotp === userotp) {
                    this.setState({ reset_approve: true });
                    localStorage.setItem("reset_approve", 1);
                } else {
                    swal('code is incorrect!');
                }

            } else {
                swal('Code is expired please resend code.')
            }

        }
    }
    componentDidMount() {
        console.table(localStorage)
        $(".loader").delay(120).fadeOut("slow");
        $("#overlayer").delay(120).fadeOut("slow");
        // });3400
        // $(window).load(function() {
        $(".loader").delay(120).fadeOut("slow");
        $("#overlayer").delay(120).fadeOut("slow");
    }
    changePassword(event) {
        event.preventDefault();
        let functions = this;
        if (this.state.password === '') {
            swal('Phone is required.')
        } else if (this.state.password.length < 6) {
            swal('Password must be 6 characters long');
        } else if (this.state.password !== this.state.repassword) {
            swal('Password not matched.');
        } else {
            this.setState({ loadingBtn: true })
            Axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.state.base_url + 'api/v2/auth/password/reset_password',
                data: {
                    phone: localStorage.getItem('phone'),
                    password: this.state.password
                }
            }).then(function (response) {
                functions.setState({ loadingBtn: false })
                if (response.data.message) {
                    localStorage.clear();
                    sessionStorage.setItem('successfull', 'Password Reset Successfully! Please login to continue');
                    functions.setState({
                        redirectToReferrer: true,
                    })
                } else {
                    swal("Error Occured! Try again");
                }
            });
        }

        // 
    }
    componentWillMount() {
        if (localStorage.getItem("approved") === 0) {
            this.setState({ redirectToReferrer: true });
        }
        if (localStorage.getItem("approved") === '1') {
            this.setState({ loginToHome: true });
        }
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { loadingBtn } = this.state;
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/' />)
        }
        return (
            <div className="yellow_bg" style={{ height: '100vh' }}>

                <span className="loader">
                    <div className="logobox">
                        <img src={logo} alt="" />
                        <img className="logo_icon" src={logo_icon} alt="" />
                        <p>Powered by Order Express</p>
                    </div>
                </span>


                <div className="login_box">
                    <div className="main_head">
                        <img src={favicon_icon} alt="" />
                        <p>آپ کی اپنی دوکان </p>
                    </div>
                    {
                        localStorage.getItem('reset_approve') === '0' ?
                            <form className="middle_form">
                                <div className="form_box">
                                    <input type="number" placeholder="Enter 6 digit code" name="otp_no" onChange={this.onChange} />
                                </div>
                                <div className="login_btn">
                                    <button type="button" onClick={this.checkCode}>Submit
                                    </button>
                                </div>
                            </form>
                            :
                            <form onSubmit={this.changePassword} className="middle_form">

                                <div className="form_box">
                                    <i className="lnr lnr-lock"></i>
                                    <input type="password" placeholder="Password" required="required" name="password" onChange={this.onChange} />
                                </div>
                                <div className="form_box">
                                    <i className="lnr lnr-lock"></i>
                                    <input type="password" placeholder="Confirm Password" required="required" name="repassword" onChange={this.onChange} />
                                </div>
                                <div className="forget_pas">
                                </div>
                                <div className="login_btn">
                                    <button
                                        type="submit"
                                        disabled={loadingBtn}
                                    >
                                        {loadingBtn && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}

                                        {!loadingBtn && <span>Submit</span>}
                                    </button>
                                </div>
                            </form>
                    }
                </div>

            </div>
        )
    }
}
export default ChangePassword;