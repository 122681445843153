import React from 'react';
import { Link } from 'react-router-dom';
import favicon_icon from '../assets/images/favicon-icon.png';
import { ProductConsumer } from "../context";
const Footer = () => {
  let shipping_charges = 0;
  let shiping_cost = localStorage.getItem('shipping_cost');
  if (shiping_cost > 0) {
    shipping_charges = shiping_cost;
  }
  return (
    <ProductConsumer>
      {value => {
        return (
          <div className="footer_bar">
            <Link to="/cart">
              <p><img src={favicon_icon} alt="" /> <b>TOTAL: Rs.{parseFloat(value.cartTotal) + parseFloat(shipping_charges)}</b></p>
            </Link>
          </div>
        );
      }}
    </ProductConsumer>
  )
}

export default Footer
