import React, { Component } from "react";
import { ProductConsumer } from "../context";
import { Link } from "react-router-dom";
import $ from 'jquery';
import swal from "sweetalert";
export default class Modal extends Component {
    constructor() {
        super();
        this.state = {
            itemCount: 1
        }
        this.increaseValue = this.increaseValue.bind(this);
        this.decreaseValue = this.decreaseValue.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    increaseValue = (value, current_stock) => {
        // console.log(current_stock)
        if (parseFloat(this.state.itemCount) < parseFloat(current_stock)) {
            let newCount = parseFloat(this.state.itemCount) + parseFloat(value);
            this.setState({ itemCount: newCount }, () => {
                console.log(this.state.itemCount);
            });
        } else {
            // swal('Only ' + current_stock + " available");
            swal('Available Quantity=' + current_stock);
        }

    }
    decreaseValue = value => {
        let newCount = parseFloat(this.state.itemCount) - parseFloat(value);
        this.setState({ itemCount: newCount }, () => {
            console.log(this.state.itemCount);
        });
    }
    closeModal() {
        $(".cancel_box,.total_box").fadeOut();
        this.setState({
            itemCount: 1
        })
    }

    render() {

        return (
            <ProductConsumer>
                {value => {
                    console.log(value)
                    const { img_url } = value;
                    const { id, thumbnail_image, name, sale_price, current_stock } = value.modalProduct;

                    return (
                        <React.Fragment>
                            <div className="cancel_box" onClick={this.closeModal}></div>
                            <div className="total_box" id="special__offer">
                                <div className="total_box_inner">
                                    <div className="close_btn_popup">
                                        <i className="lnr lnr-cross " onClick={this.closeModal}></i>

                                        <div className="qty_box" id="special_offer_popup">
                                            <div className="inner_box">
                                                <img className="cocacola_img" src={img_url + thumbnail_image} alt="" />
                                                <h4>{name}</h4>
                                                <b>Rs. {sale_price}</b>
                                                <div className="number qty__number">
                                                    <span onClick={() => {
                                                        this.decreaseValue(1);
                                                    }} className="minus">-</span>
                                                    <input type="number" className="middle_qty" onChange={(e) => {
                                                        this.increaseValue(e.target.value);
                                                    }} value={this.state.itemCount} />
                                                    <span onClick={() => this.increaseValue(1, current_stock)} className="plus">+</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="delivery_box">
                                            <h6>ڈلیوری! کل</h6>
                                            <p>اگر شام 6 بجے سے پہلے آرڈر کریں گے</p>
                                            <ul>
                                                {
                                                    current_stock > 0 ?
                                                        <li><Link onClick={() => {
                                                            this.closeModal();
                                                            value.addToCart(value.modalProduct, id, this.state.itemCount);
                                                        }}>ٹوکری میں شامل کریں </Link></li>
                                                        :
                                                        <li><Link >اسٹاک موجود نہیں ہے </Link></li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }}
            </ProductConsumer>
        );
    }
}
