import React, { Component } from 'react';
import logo from '../assets/images/LOGO.png';
import logo_icon from '../assets/images/Vector-Smart-Object.png';
import favicon_icon from '../assets/images/favicon-icon.png';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import Axios from "axios";
import { api_url } from '../Config';
import swal from 'sweetalert';
class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            redirectToReferrer: false,
            phone: "",
            loadingBtn: false,
            loginToHome: false,
        };
        this.sendPhone = this.sendPhone.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        console.table(localStorage)
        $(".loader").delay(120).fadeOut("slow");
        $("#overlayer").delay(120).fadeOut("slow");
        // });3400
        // $(window).load(function() {
        $(".loader").delay(120).fadeOut("slow");
        $("#overlayer").delay(120).fadeOut("slow");
    }
    sendPhone(event) {
        event.preventDefault();

        let functions = this;
        if (this.state.phone === '') {
            swal('Phone required.')
        } else if (this.state.phone.length !== 10) {
            swal('Invalid Phone Number format. Format should be 3XXXXXXXXX');
        } else {
            this.setState({ loadingBtn: true })
            Axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.state.base_url + 'api/v2/auth/password/reset_code',
                data: {
                    phone: '+92' + this.state.phone
                }
            }).then(function (response) {
                functions.setState({ loadingBtn: false })
                if (response.data.user_id) {
                    localStorage.setItem("reset_token_id", response.data.opt_code);
                    localStorage.setItem("phone", response.data.phone);
                    localStorage.setItem("user_id", response.data.user_id);
                    localStorage.setItem("reset_approve", 0);
                    functions.setState({
                        redirectToReferrer: true,
                    })
                } else {
                    swal("Phone is incorrect");
                }
            });
        }

        // 
    }
    componentWillMount() {
        if (localStorage.getItem("approved") === 0) {
            this.setState({ redirectToReferrer: true });
        }
        if (localStorage.getItem("approved") === '1') {
            this.setState({ loginToHome: true });
        }
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { loadingBtn } = this.state;
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/change-password' />)
        }
        return (
            <div className="yellow_bg" style={{ height: '100vh' }}>

                <span className="loader">
                    <div className="logobox">
                        <img src={logo} alt="" />
                        <img className="logo_icon" src={logo_icon} alt="" />
                        <p>Powered by Order Express</p>
                    </div>
                </span>


                <div className="login_box">
                    <div className="main_head">
                        <img src={favicon_icon} alt="" />
                        <p>آپ کی اپنی دوکان </p>
                    </div>
                    <div className="main_head">
                        <p>اپنا رجسٹرڈ نمبر درج کریں۔ </p>
                    </div>
                    <form onSubmit={this.sendPhone} className="middle_form">

                        <div className="form_box number_add">
                            <i className="lnr lnr-phone"></i>
                            <span className="country_code">+92</span>
                            <input type="text" placeholder="3XXXXXXXXX" required="required" name="phone" onChange={this.onChange} />
                        </div>

                        <div className="forget_pas">
                        </div>
                        <div className="login_btn">
                            <button
                                type="submit"
                                disabled={loadingBtn}
                            >
                                {loadingBtn && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}

                                {!loadingBtn && <span>Submit</span>}
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        )
    }
}
export default ChangePassword;