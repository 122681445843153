import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
// import $ from 'jquery';
import Header from '../component/Header';
class Profile extends Component {
    constructor() {
        super();
        this.state = {
            trackorder: "",
        }
        this.onChange = this.onChange.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {

        return (
            <React.Fragment>


                <div className="yellow_bg border_none">

                    <Header title="Track Order" link="/home" />

                    <section className="login_box__view listing_view purple_bg" id="height_box" style={{ height: '100vh' }}>
                        <div className="main_wrap dashboard_box">
                            <form action="" method="">
                                <div className="track_order">
                                    <h4>Track Your Order</h4>
                                    <input type="number" name="trackorder" onChange={this.onChange} />
                                    <Link to={`/orderdetails/${this.state.trackorder}`}> <button type="button">VIEW YOUR STATUS</button></Link>
                                </div>
                            </form>

                        </div>
                    </section>

                </div>


            </React.Fragment>
        )
    }
}
export default Profile;
