import React from 'react';
import { Link } from 'react-router-dom';
const NotFound = () =>
    <div class="notfound_box">
        <h3>404 page not found</h3>
        <p>We are sorry but the page you are <span> looking for does not exist.</span></p>
        <Link to="/">Home</Link>






    </div>

export default NotFound;