import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Login from './container/Login.js';
import Brands from './container/Brands.js';
import Cart from './container/Cart.js';
import Register from './container/Register.js';
import Home from './container/Home.js';
import ItemList from './container/ItemList.js';
import OrderDetails from './container/OrderDetails';
import Checkout from './container/Checkout.js';
import OrderAddress from './container/OrderAddress.js';
import MyOrder from './container/MyOrder.js';
import Otpno from './container/Otpno.js';
import Profile from './container/Profile.js';
import TrackOrder from './container/TrackOrder.js';
import Wallet from './container/Wallet.js';
import NotFound from './container/NotFound.js';
import AddNewAddress from './container/AddNewAddress.js';
import DeliveryAddress from './container/DeliveryAddress.js';
import ForgetPassword from './container/ForgetPassword.js';
import ChangePassword from './container/ChangePassword.js';
// import NoPage from './container/NoPage';
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/brands/:category_id" component={Brands} />
        <Route path="/cart" component={Cart} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/register" component={Register} />
        <Route path="/home" component={Home} />
        <Route path="/itemlist/:brand_id/:category_id" component={ItemList} />
        <Route path="/myorder" component={MyOrder} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/orderaddress" component={OrderAddress} />
        <Route path="/orderdetails/:order_id" component={OrderDetails} />
        <Route path="/otpno" component={Otpno} />
        <Route path="/profile" component={Profile} />
        <Route path="/trackorder" component={TrackOrder} />
        <Route path="/wallet" component={Wallet} />
        <Route path="/add-new-address" component={AddNewAddress} />
        <Route path="/delivery-address" component={DeliveryAddress} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
