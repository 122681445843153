import React, { Component } from 'react';
// import { Link, } from 'react-router-dom';
import favicon_icon from '../assets/images/favicon-icon.png';
import Axios from "axios";
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import { api_url } from '../Config';
class otpno extends Component {
    constructor() {
        super();
        this.state = {
            time: {},
            seconds: 120,
            base_url: api_url.base_url,
            otp_no: 0,
            redirectToReferrer: false,
            redirectToLogin: false,
            resendLoading: false,
            showResend: false,
        };
        this.timer = 0;
        this.countDown = this.countDown.bind(this);
        this.checkOTP = this.checkOTP.bind(this);
        this.onChange = this.onChange.bind(this);
        this.resend = this.resend.bind(this);
        this.backToHome = this.backToHome.bind(this);
        this.setTimeCounting = this.setTimeCounting.bind(this);
    }
    checkOTP(event) {
        event.preventDefault();
        if (this.state.otp_no === 0) {
            swal('Please enter 6 digit code.')
        } else {
            let dbotp = localStorage.getItem('token_id');
            if (dbotp) {
                let userotp = this.state.otp_no;
                if (dbotp === userotp) {
                    this.setState({ redirectToReferrer: true });
                    let cart = [];
                    localStorage.setItem('cart', JSON.stringify(cart));
                    localStorage.setItem("approved", 1);
                    localStorage.removeItem("password");
                    localStorage.removeItem("phone");
                } else {
                    swal('code is incorrect!');
                }

            } else {
                swal('Code is expired please resend code.')
            }

        }

    }
    resend() {
        this.timer = 0;
        this.setState({ seconds: 120, time: {}, resendLoading: true }, () => {

            this.setTimeCounting();
            let functions = this;
            Axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.state.base_url + 'api/v2/auth/loginWithApi',
                data: {
                    email: localStorage.getItem('phone'),
                    password: localStorage.getItem('password')
                }
            }).then(function (response) {
                functions.setState({ resendLoading: false })
                if (response.data.user_data) {
                    if (response.data.user_data.code === "ok") {
                        localStorage.setItem("token_id", response.data.opt_code);
                        localStorage.setItem("user_id", response.data.user_id);
                        localStorage.setItem("user_name", response.data.user_data.user);

                    } else {
                        swal("Phone or password is invalid");
                    }
                } else {
                    swal("Phone or password is invalid");
                }



            });
        });
        // 


    }
    backToHome() {
        localStorage.clear();
        this.setState({ redirectToLogin: true })
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        this.setTimeCounting()
    }

    setTimeCounting() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
        // Check if we're at zero.
        if (seconds === 0) {
            localStorage.removeItem('token_id');
            this.setState({ showResend: true })
            clearInterval(this.timer);
        }
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    componentWillMount() {
        if (localStorage.getItem("approved") === 1) {
            this.setState({ redirectToReferrer: true });
        }
    }
    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/home' />)
        }
        if (this.state.redirectToLogin) {
            return (<Redirect to='/' />)
        }
        const { resendLoading } = this.state;
        return (
            <React.Fragment>


                <div className="yellow_bg border_none">
                    <section className="login_box__view listing_view purple_bg" id="height_box" style={{ height: '100vh' }}>
                        <div className="main_wrap dashboard_box">
                            <div className="main_head">
                                <img src={favicon_icon} alt="" />
                            </div>
                            <form onSubmit={this.checkOTP} id="opt_resend">
                                <div className="track_order Otp_code">
                                    <h4>کوڈ درج کریں</h4>
                                    <p><b>{localStorage.getItem('phone')}</b> par code SMS kardiya gaya hai.</p>
                                    <input type="number" placeholder="Enter 6 digit code" name="otp_no" onChange={this.onChange} />
                                    <input className="nextbtn" type="submit" value="Next" />
                                    <h6>Baraye meharbani <b>{this.state.time.m}:{this.state.time.s}</b> intezar karein</h6>
                                </div>
                            </form>
                            <ul className="resend_box">
                                <li className="back_btn"><button onClick={this.backToHome}>Back</button></li>
                                {
                                    this.state.showResend &&
                                    <li className="resend_btn">
                                        <button
                                            type="button"
                                            onClick={this.resend}
                                            disabled={resendLoading}
                                        >
                                            {resendLoading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {!resendLoading && <span>Resend</span>}
                                        </button>
                                        {/* <button onClick={this.resend}>Resend</button> */}
                                    </li>

                                }

                            </ul>
                        </div>
                    </section>

                    {/* <div className="footer_menu">
                        <ul>
                            <li>
                                <Link to="/home">

                                    <svg viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#690048" /></svg>

                                </Link>
                            </li>

                            <li>
                                <Link to="/profile">
                                    <svg viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM12 13c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4zm6 5H6v-.99c.2-.72 3.3-2.01 6-2.01s5.8 1.29 6 2v1z" fill="#690048"></path></svg>

                                </Link>
                            </li>

                            <li>
                                <Link to="login">
                                    <svg viewBox="0 0 24 24"><path d="M5 3h6a3 3 0 0 1 3 3v4h-1V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm3 9h11.25L16 8.75l.664-.75l4.5 4.5l-4.5 4.5l-.664-.75L19.25 13H8v-1z" fill="#690048" />
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div> */}

                </div>


            </React.Fragment>
        )
    }
}
export default otpno;
