import React, { Component } from "react";
import { detailProduct } from "./data";
import $ from 'jquery';
import { api_url } from './Config';
import swal from "sweetalert";
const ProductContext = React.createContext();

class ProductProvider extends Component {
    state = {
        products: [],
        detailProduct: detailProduct,
        cart: JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : [],
        error_cart: JSON.parse(sessionStorage.getItem('error_cart')) ? JSON.parse(sessionStorage.getItem('error_cart')) : [],
        modalOpen: false,
        modalProduct: detailProduct,
        cartSubTotal: 0,
        cartTax: 0,
        cartTotal: localStorage.getItem('grand_total') ? localStorage.getItem('grand_total') : 0,
        brandid: 0,
        default_img: 'assets/img/placeholder.jpg',
        img_url: api_url.img_url,
        base_url: api_url.base_url,
    };
    componentDidMount() {
        // let storageCart = localStorage.getItem('cart');
        if (!localStorage.getItem('cart')) {
            this.setState(() => {
                return { cart: [], cartTotal: 0 };
            });
        }

    }

    setProducts = () => {


    };

    resetCart = () => {
        let error_cart = JSON.parse(sessionStorage.getItem('error_cart')) ? JSON.parse(sessionStorage.getItem('error_cart')) : [];
        if (error_cart.length > 0) {
            error_cart.map((single) => {
                let id = single.id;
                let product = this.getItem(id);
                let enterValue = single.stock_available;
                let oldQty = product.cartCount;
                let newQty = enterValue - oldQty;
                this.addToCart(product, id, newQty);
                this.setState({ error_cart: [] })


            })
        }
    }
    getItem = id => {
        const product = this.state.cart.find(item => item.id === id);
        return product;
    };
    handleDetail = id => {
        const product = this.getItem(id);
        this.setState(() => {
            return { detailProduct: product };
        });
    };
    addToCart = (product, id, enterValue) => {

        if (!localStorage.getItem('cart')) {
            this.setState(() => {
                return { cart: [], cartTotal: 0 };
            });
        }
        // Check if already on cart:
        let PrevQty = 0;
        let tempCartProducts = [...this.state.cart];
        const indexinCart = tempCartProducts.indexOf(this.getItem(id));
        const cartProduct = tempCartProducts[indexinCart];

        if (cartProduct && cartProduct !== "") {
            PrevQty = cartProduct['cartCount'];
        }
        let newQty = parseInt(PrevQty) + parseInt(enterValue);
        product['cartCount'] = newQty;
        product['total'] = newQty * product['sale_price'];
        if (cartProduct && cartProduct !== "") {
            this.setState(() => {
                return {
                    cart: [...this.state.cart]
                };
            }, this.addTotals);
        } else {
            this.setState(() => {
                return {
                    cart: [...this.state.cart, product]
                };
            }, this.addTotals);
        }

    };
    openModal = product => {
        $(".cancel_box,.total_box").fadeIn();
        // console.log(product)
        this.setState(() => {
            return { itemCount: 1, modalProduct: product, modalOpen: true };
        });
    };
    closeModal = () => {
        this.setState(() => {
            return { modalOpen: false };
        });
    };

    increment = (id, current_stock, quantity) => {
        // if (parseFloat(quantity) < parseFloat(current_stock)) {
        let tempCart = [...this.state.cart];
        const selectedProduct = tempCart.find(item => {
            return item.id === id;
        });
        const index = tempCart.indexOf(selectedProduct);
        const product = tempCart[index];
        product.cartCount = product.cartCount + 1;
        product.total = product.cartCount * product.sale_price;
        this.setState(() => {
            return {
                cart: [...tempCart]
            };
        }, this.addTotals);

        // } else {
        // swal('Only ' + current_stock + " available");
        // swal('Available Quantity=' + current_stock);
        // }

    };
    decrement = id => {
        let tempCart = [...this.state.cart];
        const selectedProduct = tempCart.find(item => {
            return item.id === id;
        });
        const index = tempCart.indexOf(selectedProduct);
        const product = tempCart[index];
        product.cartCount = product.cartCount - 1;
        if (product.cartCount === 0) {
            this.removeItem(id);
        } else {
            product.total = product.cartCount * product.sale_price;
            this.setState(() => {
                return { cart: [...tempCart] };
            }, this.addTotals);
        }
    };
    getTotals = () => {
        let subTotal = 0;
        this.state.cart.map(item => (subTotal += item.total));
        const tempTax = subTotal * 0;
        const tax = parseFloat(tempTax.toFixed(2));
        const total = subTotal + tax;
        let cart = this.state.cart;
        localStorage.setItem('cart', JSON.stringify(cart));
        localStorage.setItem('grand_total', total);
        return {
            subTotal,
            tax,
            total
        };
    };
    addTotals = () => {
        const totals = this.getTotals();
        this.setState(
            () => {
                return {
                    cartSubTotal: totals.subTotal,
                    cartTax: totals.tax,
                    cartTotal: totals.total
                };
            },
            () => {
                // console.log(this.state);
            }
        );
    };
    removeItem = id => {
        let tempCart = [...this.state.cart];
        tempCart = tempCart.filter(item => {
            return item.id !== id;
        });

        this.setState(() => {
            return {
                cart: [...tempCart]
            };
        }, this.addTotals);
    };
    clearCart = () => {
        localStorage.setItem('shipping_cost', 0);
        this.setState(
            () => {
                return { cart: [] };
            },
            () => {
                this.setProducts();
                this.addTotals();
            }
        );
    };
    render() {
        return (
            <ProductContext.Provider
                value={{
                    ...this.state,
                    handleDetail: this.handleDetail,
                    addToCart: this.addToCart,
                    openModal: this.openModal,
                    closeModal: this.closeModal,
                    increment: this.increment,
                    decrement: this.decrement,
                    removeItem: this.removeItem,
                    clearCart: this.clearCart,
                    resetCart: this.resetCart,
                }}
            >
                {this.props.children}
            </ProductContext.Provider>
        );
    }
}

const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer };
