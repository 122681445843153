import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import emptycard from '../assets/images/emtpycard.png';
import { ProductConsumer } from "../context";
import Header from '../component/Header';
import { api_url } from '../Config';
import axios from 'axios';
import vectorsmart from '../assets/images/Vector-Smart-Object.png';
import logo from '../assets/images/LOGO.png';
import $ from 'jquery';
class Cart extends Component {
    constructor() {
        super();
        this.state = {
            redirectToReferrer: false,
            shippingCharges: 0,
            base_url: api_url.base_url,
            success_message: (sessionStorage.getItem('cart_msg')) ? sessionStorage.getItem('cart_msg') : '',
        }
        this.calculateShipping = this.calculateShipping.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    closeModal() {
        $(".overlay_poppu,.popup_orderbox").fadeOut();
    }
    componentDidMount() {
        if (sessionStorage.getItem('cart_msg')) {
            // window.location.reload();
            $(".overlay_poppu,.popup_orderbox").fadeIn();
            sessionStorage.removeItem('cart_msg')
        }
        this.calculateShipping();
    }
    calculateShipping() {
        let user_id = localStorage.getItem('user_id');
        let grand_total = localStorage.getItem('grand_total');
        if (grand_total > 0) {
            axios.get(this.state.base_url + 'api/v2/shipping_cost/' + user_id + '/' + grand_total)
                .then((response) => {
                    // console.log(response.data)
                    this.setState({
                        shippingCharges: response.data.shipping_charges
                    }, () => {
                        localStorage.setItem('shipping_cost', this.state.shippingCharges)
                    });

                });
        }
    }
    componentWillMount() {
        if (!localStorage.getItem("approved")) {
            this.setState({ redirectToReferrer: true });
        }
    }
    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/' />)
        }

        return (
            <ProductConsumer>
                {value => {
                    return (
                        <React.Fragment>


                            <div className="yellow_bg border_none login_Bg">

                                <Header title="My Cart" link="/home" />
                                <div className="row">
                                    {(
                                        this.state.success_message !== '' ?
                                            <React.Fragment>
                                                <div className="overlay_poppu"></div>
                                                <div className="popup_orderbox" style={{ zIndex: '9999', position: 'fixed' }}>
                                                    <div className="overlayimg">
                                                        <img src={vectorsmart} alt="" />
                                                    </div>
                                                    <div className="close_btn_icon">
                                                        <i onClick={() => {
                                                            this.closeModal();
                                                            value.resetCart();
                                                        }} className="lnr lnr-cross"></i>
                                                        <img src={logo} alt="" />


                                                        {<p className="shukria_box">Product Quantity Exceeded!</p>}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            : ""
                                    )}
                                </div>
                                <section className="login_box__view listing_view" id="height_box">
                                    <div className="order_details  myroute_search wallet_white" id="wallet_page">
                                        {
                                            value.cart.length > 0 &&
                                            <div className="info_order">
                                                <h4>My Cart ({value.cart.length} items)</h4>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Sub Total</th>
                                                            <td className="order_total fee_green">Rs.{value.cartTotal}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Shipping Charges</th>
                                                            <td className="order_total fee_green">{this.state.shippingCharges}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th>Pending Payment</th>
                                                            <td className="order_total fee_green">PKR -299</td>
                                                        </tr> */}
                                                        <tr>
                                                            <th>Total bill</th>
                                                            <td className="order_total fee_green">Rs.{parseFloat(value.cartTotal) + parseFloat(this.state.shippingCharges)}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th>Your total savings</th>
                                                            <td className="order_total fee_green total_amount">Rs.2063</td>
                                                        </tr> */}

                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        <div id="cart__items">
                                            {
                                                value.cart.length > 0 ? value.cart.map((data, id) => {
                                                    return (
                                                        <div className="qty_box" key={id}>
                                                            <div className="inner_box">
                                                                <img className="cocacola_img" src={value.img_url + data.thumbnail_image} alt="" />
                                                                <h4>{data.name}</h4>
                                                                <b>Rs.{data.total}
                                                                    {
                                                                        data.original_price > 0 &&
                                                                        <h6>Rs.{data.original_price * data.cartCount}</h6>
                                                                    }
                                                                </b>
                                                                <div className="number qty__number">
                                                                    <span onClick={() => {
                                                                        value.decrement(data.id);
                                                                        this.calculateShipping();
                                                                    }} className="minus">-</span>
                                                                    <input type="number" readOnly={true} className="middle_qty" value={data.cartCount} />
                                                                    <span onClick={() => {
                                                                        value.increment(data.id, data.current_stock, data.cartCount);
                                                                        this.calculateShipping();
                                                                    }} className="plus">+</span>
                                                                </div>
                                                                <Link to="#" onClick={() => {
                                                                    value.removeItem(data.id);
                                                                }} className="order_cancel">کینسل کریں</Link>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                    :

                                                    <div className="empty_card">
                                                        {
                                                            localStorage.setItem('shipping_cost', 0)
                                                        }
                                                        <b>Cart is Empty</b>
                                                        <img src={emptycard} alt="emptycard" />
                                                    </div>



                                            }

                                        </div>
                                    </div>
                                </section>

                                {
                                    value.cart.length > 0 &&
                                    <div className="procced_checkout">
                                        <Link to="/checkout">
                                            <div className="row">
                                                <div className="col-sm-6 checkout_info">
                                                    <h4>Proceed to Checkout</h4>
                                                </div>
                                                <div className="col-sm-6 checkout_info checkout_value">
                                                    <h6>Rs. {parseFloat(value.cartTotal) + parseFloat(this.state.shippingCharges)}</h6>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                }




                            </div>

                        </React.Fragment>
                    );
                }}
            </ProductConsumer>
        )
    }
}
export default Cart;
