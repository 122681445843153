import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Modal from '../component/Modal';
import { api_url } from '../Config';
import axios from 'axios';
import loader from '../assets/images/qty_boxes_03.png';
import { ProductConsumer } from "../context";
class Brands extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            products: [],
            mainCategory: [],
            brands: [],
            img_url: api_url.img_url,
            default_img: 'assets/img/placeholder.jpg',
            brandsLoading: false,
            productsLoading: false,
        }
    }
    componentDidMount() {
        this.setState({ brandsLoading: true, productsLoading: true })
        axios.get(this.state.base_url + 'api/v2/products/category/' + this.props.match.params.category_id)
            .then((response) => {

                if (response.status === 200) {
                    this.setState({
                        products: response.data.data,
                        productsLoading: false
                    })
                }
            });
        axios.get(this.state.base_url + 'api/v2/brands/' + this.props.match.params.category_id)
            .then((response) => {
                // console.log(response.data)
                this.setState({
                    brands: response.data.data,
                    brandsLoading: false
                })
            });
    }
    componentWillMount() {
        if (!localStorage.getItem("approved")) {
            this.setState({ redirectToReferrer: true });
        }
    }
    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/' />)
        }
        return (
            <ProductConsumer>
                {value => {
                    return (
                        <React.Fragment>
                            <Modal />
                            <div className="yellow_bg border_none">
                                <Header title="Oreder Experess" tagline="دوکان" link="/home" />
                                <div className="yellow_bar"></div>
                                <div className="brand_bg">
                                    <h4>BRANDS</h4>
                                </div>
                                <div className="brands_logos home_brands" id="brand__name">
                                    <ul>
                                        {
                                            this.state.brandsLoading ?
                                                <div className="loading_img">
                                                    <img src={loader} alt="loader" />
                                                </div>
                                                :
                                                this.state.brands.length > 0 ? this.state.brands.map((data, id) => {

                                                    return (
                                                        <li key={id}>
                                                            <Link to={`/itemlist/${data.brand_id}/${data.category_id}`}>
                                                                {/* <h4>{data.name}</h4> */}
                                                                <img className="cocacola_img" src={this.state.img_url + data.logo} alt="" />
                                                            </Link>
                                                        </li>
                                                    );
                                                })
                                                    :
                                                    "No brand for this category"

                                        }
                                    </ul>
                                </div>




                                <div className="yellow_bar" id="dark_bar"></div>
                                <div className="brand_bg" id="special_offer">
                                    <h4>Items List</h4>
                                </div>
                                <div id="myCarousel" className="carousel slide" data-ride="carousel">

                                    <div className="slider_box" id="items_listing">
                                        <div className="special_offer_box">
                                            <ul>

                                                {
                                                    this.state.productsLoading ?
                                                        <div className="loading_img">
                                                            <img src={loader} alt="loader" />
                                                        </div>
                                                        :
                                                        this.state.products.length > 0 ? this.state.products.map((data, id) => {
                                                            return (
                                                                <li key={id}>
                                                                    <div className="items_box">
                                                                        <div className="tag_box">
                                                                            <h6>{data.unit}</h6>
                                                                        </div>
                                                                        <img src={value.img_url + data.thumbnail_image} alt="" />
                                                                        <div className="row">
                                                                            <div className="col-sm-12 brand_fulname">
                                                                                <span>{data.name} </span>
                                                                            </div>

                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-6 line_through">
                                                                                {
                                                                                    data.original_price > 0 &&
                                                                                    <h5>Rs. {data.original_price}</h5>
                                                                                }
                                                                            </div>
                                                                            <div className="col-sm-6 brand__name">
                                                                                <span className="price__box">Rs. {data.sale_price}</span>
                                                                            </div>
                                                                        </div>
                                                                        <Link
                                                                            to="#"
                                                                            onClick={() => {
                                                                                // value.addToCart(id);
                                                                                value.openModal(data);
                                                                            }}
                                                                        >
                                                                            ابھی خریدیں
                                                                        </Link>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                            :
                                                            "No products found"
                                                }

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <Footer />
                        </React.Fragment>
                    )
                }
                }
            </ProductConsumer >
        )
    }
}
export default Brands;
