import React, { Component } from 'react';
// import { Link, } from 'react-router-dom';
import Header from '../component/Header';
class Checkout extends Component {


  render() {

    return (
      <React.Fragment>


        <div className="yellow_bg border_none ">

          <Header title="Wallet" />
          <section className="login_box__view listing_view purple_bg" id="height_box" style={{ height: '100vh' }}>
            <div className="checkout_textarea order_details searchbar myroute_search" id="wallet_page">
              <div class="info_order">
                <h4>Wallet</h4>
                <table class="table">
                  <tbody>
                    {/*<tr>
          <th>Personal Balance</th>
          <td class="order_total fee_green">Rs. 2299</td>
        </tr>*/}
                    <tr>
                      <th>Available Balance</th>
                      <td class="order_total fee_green">Rs. {localStorage.getItem('balance')}</td>
                    </tr>
                    {/*<tr>
          <th>Promotional Balance</th>
          <td class="order_total fee_green">PKR 2299</td>
        </tr>*/}

                  </tbody>
                </table>
              </div>



              <div className="info_order">
                <h4>Current Payment Method</h4>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>
                        <div className="gendar">
                          <input checked={true} type="radio" id="test20" name="radio-group" />
                          <label for="test20">(COD) <svg id="cod" viewBox="0 0 24 24"><path d="M11 4h1v2.01c3.29.141 4 1.685 4 2.99h-1c0-1.327-1.178-2-3.5-2c-.82 0-3.5.163-3.5 2.249c0 .872 0 1.86 3.621 2.766l1.606.485C15.76 13.435 16 14.572 16 15.751c0 1.881-1.518 3.093-4 3.235V21h-1v-2.01c-3.29-.141-4-1.685-4-2.99h1c0 1.327 1.178 2 3.5 2c.82 0 3.5-.163 3.5-2.249c0-.872 0-1.86-3.621-2.766L9.773 12.5C7.24 11.565 7 10.428 7 9.249c0-1.881 1.518-3.093 4-3.235V4z" fill="#626262"></path></svg></label>
                        </div>
                      </th>
                      <td className="check_mark"><svg viewBox="0 0 24 24"><path d="M18.9 8.1L9 18l-4.95-4.95l.707-.707L9 16.586l9.192-9.193l.707.708z" fill="#51aa1a"></path></svg></td>
                    </tr>


                  </tbody>
                </table>
              </div>

            </div>
          </section>


          {/* <div className="footer_menu">
            <ul>
              <li>
                <Link to="/home">

                  <svg viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#690048" /></svg>

                </Link>
              </li>

              <li>
                <Link to="/profile">
                  <svg viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM12 13c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4zm6 5H6v-.99c.2-.72 3.3-2.01 6-2.01s5.8 1.29 6 2v1z" fill="#690048"></path></svg>

                </Link>
              </li>

              <li>
                <Link to="login">
                  <svg viewBox="0 0 24 24"><path d="M5 3h6a3 3 0 0 1 3 3v4h-1V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm3 9h11.25L16 8.75l.664-.75l4.5 4.5l-4.5 4.5l-.664-.75L19.25 13H8v-1z" fill="#690048" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div> */}

        </div>


      </React.Fragment>
    )
  }
}
export default Checkout;
