import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
// import Footer from '../component/Footer';
import Header from '../component/Header';
import { api_url } from '../Config';
import Axios from 'axios';
// import $ from 'jquery';
import loader from '../assets/images/favicon-icon.png';
class OrderDetails extends Component {
    constructor() {
        super();
        this.state = {
            redirectToReferer: false,
            base_url: api_url.base_url,
            products: [],
            orders: [],
            customer: [],
            loading: false,
            productsLoading: false,
            noRecordFound: false
        };
    }
    componentDidMount() {
        let order_id = this.props.match.params.order_id;
        this.setState({ loading: true });
        this.setState({ productsLoading: true });

        Axios.get(this.state.base_url + 'api/v2/order/' + order_id)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data && response.data.data.length > 0) {
                        this.setState({
                            orders: response.data.data[0],
                            loading: false
                        }, () => {
                            Axios.get(response.data.data[0].links.user)
                                .then((response) => {
                                    if (response.status === 200) {
                                        this.setState({
                                            customer: response.data.data[0],
                                            loading: false
                                        });
                                    }
                                });
                            Axios.get(response.data.data[0].links.order_detail)
                                .then((response) => {
                                    if (response.status === 200) {
                                        this.setState({
                                            products: response.data.data,
                                            productsLoading: false
                                        });
                                    }
                                    this.setState({ productsLoading: false })
                                });
                        });
                    } else {
                        this.setState({ noRecordFound: true })
                    }
                }
            });

    }

    render() {
        function paymentType(payment) {
            if (payment === 'cash_on_delivery') {
                return 'COD';
            } else {
                return payment
            }
        }
        return (
            <React.Fragment>


                <body className="yellow_bg border_none">


                    <Header title="Order Details" link="/myorder" />

                    <section className="login_box__view listing_view purple_bg" id="height_box" style={{ height: '100vh' }}>
                        <div className="order_details searchbar myroute_search purple_bg" id="order_info">
                            {
                                this.state.noRecordFound ?
                                    <div className="loading_img checkout_loaders">
                                        <img src={loader} alt="loader" />
                                        <p style={{ color: '#fff' }}>No Record Found</p>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className="info_order">
                                            <h4>Customer Information</h4>
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{this.state.customer.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone</th>
                                                        <td style={{ fontSize: '14px' }}><Link to={`tel:${this.state.customer.phone}`} className="phone_tel">{this.state.customer.phone}</Link></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Shipping Address</th>
                                                        <td className="Address_icon"><Link to="#">{this.state.orders.shipping_address} <i className="fa fa-map-marker"></i></Link></td>
                                                    </tr>
                                                </tbody></table>
                                        </div>

                                        <div className="info_order">
                                            <h4>Order Information</h4>
                                            <table className="table">
                                                <tbody><tr>
                                                    <th>Order  Code</th>
                                                    <td>{this.state.orders.code}</td>
                                                </tr>
                                                    <tr>
                                                        <th>Order Date</th>
                                                        <td>{this.state.orders.created_at}</td>
                                                    </tr>
                                                    <tr>
                                                        <th> Time Selected</th>
                                                        <td style={{ fontSize: '12px' }} >Fri-09-jul (11:00 am-1:30 pm)</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Order Status</th>
                                                        <td>{this.state.orders.payment_status}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total  amount</th>
                                                        <td style={{ fontSize: '17px', color: ' #591845', fontWeight: '900' }} >Rs:{this.state.orders.grand_total}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Payment Method</th>
                                                        <td>{paymentType(this.state.orders.payment_type)}</td>
                                                    </tr>
                                                </tbody></table>
                                        </div>

                                        <div className="info_order">
                                            <h4>Order Details</h4>
                                            <table className="table head_table">
                                                <tbody>
                                                    <tr className="table_info">
                                                        <th>Items</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                    </tr>

                                                </tbody>
                                                <tbody>
                                                    {
                                                        this.state.productsLoading ?
                                                            <div className="loading_img checkout_loaders">
                                                                <img src={loader} alt="loader" />
                                                            </div>
                                                            :
                                                            this.state.products.length > 0 &&

                                                            this.state.products.map((data, id) => {

                                                                return (
                                                                    <tr key={id}>
                                                                        <th>{data.product_name}</th>
                                                                        <td><span>{data.quantity}</span></td>
                                                                        <td><span>{data.price}</span></td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>


                                            <table className="table">
                                                <tfoot>
                                                    <tr>
                                                        <th>Sub Total</th>
                                                        <td>Rs.{this.state.orders.items_price}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Shipping Charges</th>
                                                        <td>Rs.{this.state.orders.shipping_cost}</td>
                                                    </tr>
                                                    <tr id="total_orders">
                                                        <th>Total Amount</th>
                                                        <td>Rs.{this.state.orders.grand_total}</td>
                                                    </tr>

                                                </tfoot>
                                            </table>


                                        </div>
                                    </React.Fragment>
                            }


                        </div>
                    </section>
                </body>

            </React.Fragment>
        )
    }
}
export default OrderDetails;
